<template>
    <f-card>
		<v-data-table
			:headers="headers"
			:items="languages"
			@click:row="edit" />
	</f-card>
</template>

<script>
export default {
	data: () => ({
		headers: [
			{
				text: 'ID',
				align: 'start',
				sortable: true,
				value: 'id',
			},
			{
				text: 'Idioma',
				align: 'start',
				sortable: true,
				value: 'language',
			},
			{
				text: 'Abreviação',
				align: 'start',
				sortable: true,
				value: 'abbreviation',
			},
			{ 
				text: 'Criado em',
				value: 'created_at',
				align: 'end'
			},
		]
	}),
	computed: {
		languages() { return this.$store.state.languages.list },
		loading() { return !this.$store.state.system.loading.done }
	},
	methods: {
		edit( language ) {
			console.log( 'TODO: editar' )
			// this.$router.push({
			// 	name: 'settings-job-roles-edit',
			// 	params: {
			// 		id: jobRole.id
			// 	}
			// })
		}
	}
}
</script>