<template>
    <div>
        <f-default-header
			title="Marketing - Items - Idiomas"
			subtitle="Novo" />
        

        <f-card-spacer />

        <f-create-edit-form />

        <f-actions-btn-holder>
            <v-btn
                color="secondary"
                elevation="0"
                large
                @click="back"
                :disabled="false">

                cancelar
            </v-btn>
            &nbsp;
            <v-btn
                color="primary"
                elevation="0"
                large
                @click="save"
                :disabled="false">

                {{false?'salvando':'salvar'}}
            </v-btn>
        </f-actions-btn-holder>
    </div>
</template>

<script>
import FCreateEditForm from '../../../../components/mkt/items/LanguageCreateEditForm.vue'


export default {
    components: {
        FCreateEditForm
    },
    methods: {
        async save(){
            await this.$store.dispatch('languages/create')
            this.back()
        },
        back(){
            this.$router.push({
				name: 'mkt-items-languages-list'
			})
        }
    }
}
</script>