<template>
	<div>
		<f-default-header
			title="Marketing"
			subtitle="Items - Idiomas - Listagem" />

		<f-list />

		<f-actions-btn-holder>
			<v-btn
				color="primary"
				elevation="0"
				large
				@click="create">

				novo idioma
			</v-btn>
		</f-actions-btn-holder>
	</div>
</template>

<script>
import FList from '../../../../components/mkt/items/LanguagesList.vue'


export default {
	components: {
		FList
	},
	async created() {
		this.list()
	},
	methods: {
		create() {
			this.$router.push({
				name: 'mkt-items-languages-create'
			})
		},
		async list() {
            this.$store.dispatch( 'system/loading', 'data' )
            await this.$store.dispatch( 'languages/list' )
            this.$store.dispatch( 'system/loading', 'done' )
        },
	}
}
</script>